header {
  margin-bottom: 60px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 48px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg data-name='Group 255' xmlns='http://www.w3.org/2000/svg' width='1267' height='47'%3E%3Cpath data-name='Rectangle 150' fill='%23a60e3f' d='M41 35v8h-8v-8z'/%3E%3Cpath data-name='Rectangle 151' fill='%233f438b' d='M112 26v8h-8v-8z'/%3E%3Cpath data-name='Rectangle 152' fill='%23a60e3f' d='M139 39v8h-8v-8z'/%3E%3Cpath data-name='Rectangle 153' fill='%23a60e3f' d='M194 29v8h-8v-8z'/%3E%3Cpath data-name='Rectangle 154' fill='%233f438b' d='M8 0v8H0V0z'/%3E%3Cpath data-name='Rectangle 150' fill='%23a60e3f' d='M1169 35v8h-8v-8z'/%3E%3Cpath data-name='Rectangle 151' fill='%233f438b' d='M397 19v8h-8v-8z'/%3E%3Cpath data-name='Rectangle 157' fill='%233f438b' d='M584 23v8h-8v-8z'/%3E%3Cpath data-name='Rectangle 152' fill='%23a60e3f' d='M1267 39v8h-8v-8z'/%3E%3Cpath data-name='Rectangle 154' fill='%233f438b' d='M1126 10v8h-8v-8z'/%3E%3Cpath data-name='Rectangle 159' fill='%233f438b' d='M919 19v8h-8v-8z'/%3E%3Cpath data-name='Rectangle 150' fill='%23a60e3f' d='M560 19v-8h8v8z'/%3E%3Cpath data-name='Rectangle 160' fill='%23a60e3f' d='M789 15V7h8v8z'/%3E%3Cpath data-name='Rectangle 158' fill='%23a60e3f' d='M945 16V8h8v8z'/%3E%3Cpath data-name='Rectangle 151' fill='%233f438b' d='M256 27v-8h8v8z'/%3E%3Cpath data-name='Rectangle 156' fill='%233f438b' d='M218 26v-8h8v8z'/%3E%3Cpath data-name='Rectangle 152' fill='%23a60e3f' d='M472 36v-8h8v8z'/%3E%3Cpath data-name='Rectangle 155' fill='%23a60e3f' d='M733 19v-8h8v8z'/%3E%3Cpath data-name='Rectangle 153' fill='%23a60e3f' d='M368 15V7h8v8z'/%3E%3Cpath data-name='Rectangle 154' fill='%233f438b' d='M665 23v-8h8v8z'/%3E%3C/svg%3E");
    background-position: 50%;
    background-repeat: no-repeat;
  }
}
@media (max-width: 767.98px) {
  .header {
    padding-top: 50px;
  }
}
.header__logo img {
  width: 202px;
  height: auto;
}
.header__partners {
  margin-top: 74px;
  margin-bottom: 25px;

  img {
    height: 2rem;
    width: auto;

    &:not(:last-child) {
      margin-right: 24px;
    }
  }
}

.header .navbar-nav .nav-item {
  margin: 0 9px;
}
.header .navbar-nav .nav-link {
  font-size: .8125rem;
  font-weight: 700;
  line-height: 1.23;
  color: #3f438b;
  padding: 0;
  text-decoration: none;
}

.menu .nav-drill {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  transition: .45s;
  transform: translateX(100%);
  padding: 12.25rem 0 3rem;
  font-size: 1rem;
  z-index: 100;
}
@media (max-width: 767.98px) {
  .menu .nav-drill {
    padding: 9.25rem 0 3rem;
  }
}
.menu .nav-top {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  z-index: 103;
  height: 2rem;
  padding: 2px 0 0;
}

.menu .nav-top, .menu .nav-top span:not(.wpml-ls-native):not(.wpml-ls-display) {
  transition: all .5s ease 0s;
  -webkit-transition: all .5s ease 0s;
  position: relative;
  width: 2rem;
}
.menu .nav-top span:not(.wpml-ls-native):not(.wpml-ls-display) {
  top: 0;
  display: flex;
  height: 0.125rem;
  margin: 0.5rem auto;
  background-color: #3f438b;
  z-index: 1;
  transform-origin: 0.3125rem 0;
}
.menu .nav-top span:not(.wpml-ls-native):not(.wpml-ls-display):first-child, .menu .nav-top span:not(.wpml-ls-native):not(.wpml-ls-display):last-child {
  transform-origin: 50% 50%;
}
.menu.nav-is-toggled span:not(.wpml-ls-native):not(.wpml-ls-display):first-child {
  opacity: 1;
  top: 4.5px;
  transform: rotate(45deg);
}
.menu.nav-is-toggled span:not(.wpml-ls-native):not(.wpml-ls-display):last-child {
  opacity: 1;
  top: -4.5px;
  transform: rotate(-45deg);
}
.menu.nav-is-toggled .nav-drill {
  transform: translateX(20%);
}
.header .nav-drill .navbar-nav .nav-item {
  margin-bottom: 1em;
}
.header .nav-drill .navbar-nav li a {
  width: -moz-fit-content;
  width: fit-content;
  text-decoration: none;
}

.header .nav-item .nav-link.username {
  font-weight: 300;
  cursor: text;
}