.animation-container {
  height: 150px;
  position: relative;
  overflow: hidden;

  &#header-animation {
    opacity: 0;
    transform: translateY(-50px);
    transition: opacity 1s, transform 1s;

    &.visible {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &#footer-animation {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 1s, transform 1s;

    &.visible {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .square {
    width: 10px;
    height: 10px;
    position: absolute;


    &.blue {
      background: var(--ua-blue);
    }

    &.yellow {
      background: var(--ua-yellow);
    }
  }

  .square-flower {
    position: absolute;
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 600px) {
  .animation-container {
    height: 50px;
    position: relative;
    overflow: hidden;
  }
}






