.dialog-backdrop {
  display: none;
  opacity: 0;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(10, 10, 10, 0.5);
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transition: opacity 1s;

  &.open {
    opacity: 1;
    display: flex;
  }

  .content-wrapper {
    width: 95%;
  }

  .dialog-content {
    max-width: 70%;
    min-width: 50%;
    background: rgb(255, 255, 255);
    max-height: 100%;
    overflow-y: scroll;

    .dialog-title {
      padding: 1em;
      border-bottom: 1px solid var(--hrColor);
    }
    .dialog-body {
      padding: 1em;
    }
    .dialog-actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      flex-wrap: wrap;
      padding: 1em;
      gap: 1em;

      .action {
        padding: .5em 1em;
        font-size: .8em;
      }

      .action-default {
        background: #999999;
      }
    }
  }
}


@media only screen and (max-width: 600px) {
  .dialog-backdrop {
    padding-top: 5%;
    padding-bottom: 5%;
    align-items: flex-start;
    .dialog-content {
      max-width: 95%;
      min-width: 50%;
    }
  }
}
.dialog-content {
  .form-check-info {
    padding-left: 20px;
  }
}