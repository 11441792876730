.form-control.input-attachment-name {
  border: var(--bs-border-width) solid var(--bs-border-color);
  padding: 0.25rem;
  &:disabled {
    border: none;
    background-color: transparent;
  }
}
.btn.btn-list-action {
  padding: 0.25rem 1rem;
  white-space: nowrap;
}