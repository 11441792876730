:root {
  --ua-blue: #0057b8;
  --ua-yellow: #ffd800;
  --ua-font-family: Arial, Roboto,Helvetica Neue,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
}
.ua {

  h1, h2, h3, h4 {
    color: var(--ua-blue) !important;
  }

  a {
    color: var(--ua-blue);
  }

  .content-wrapper {
    border-radius: 0 !important;
  }

  .form-wrapper {
    .form-field {
      label {
        color: var(--ua-blue) !important;
      }

      &.under-radio .field-error {
        margin: -8px 0 12px
      }
    }

    .like-label {
      font-weight: 700;
      color: var(--ua-blue) !important;
      margin-bottom: 8px;
    }

    .form-control {
      border-radius: 0;
    }

    .form-check {
      input[type='checkbox'] {
        border-radius: 0!important;
      }

      .form-check-input:checked[type=checkbox] {
        background-image: none !important;
        position: relative;
      }

      .form-check-input:checked[type=checkbox]::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 10px;
        height: 10px;
        background: var(--ua-blue);
      }
    }
  }

  .primary-button, .btn-danger {
    font-size: 1rem;
    transition: all 0.3s ease-out;
    border: 3px solid var(--ua-blue);
    color: var(--ua-blue);
    background: var(--ua-yellow);
    border-radius: 0;
    &:hover {
      border: 3px solid var(--ua-yellow);
      color: var(--ua-yellow);
      background: var(--ua-blue);
    }
  }

  .secondary-button, .btn-secondary {
    font-size: 1rem;
    transition: all 0.3s ease-out;
    border: 3px solid var(--ua-blue);
    color: var(--ua-blue);
    background: transparent;
    border-radius: 0;
    &:hover {
      color: var(--ua-yellow);
      background: var(--ua-blue);
    }
  }

  .dialog-backdrop {
    .dialog-content {
      .dialog-title {
        border-bottom: 1px solid var(--ua-blue) !important;
      }
    }
  }

  .legend {
    font-size: 1.2rem;
  }

  .hint {
    font-size: 0.9em !important;
    color: var(--bs-body-color);
    font-weight: 400;
  }

  .form-radio-input {
    margin-right: 8px;
    accent-color: var(--ua-blue);
  }

  .form-radio-label {
    display: inline-flex;
    align-items: center;
  }

  .m0 {
    margin: 0
  }
}
