footer.footer {
  position: relative;
  overflow: hidden;
  margin-top: 70px;
  padding-bottom: 154px;

  &:before {
    content: "";
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    height: 48px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg data-name= 'Group 259' xmlns= 'http://www.w3.org/2000/svg' width= '1267' height= '47' %3E%3Cpath data-name= 'Rectangle 150' fill= '%23a60e3f' d= 'M33 12V4h8v8z' /%3E%3Cpath data-name= 'Rectangle 151' fill= '%233f438b' d= 'M104 21v-8h8v8z' /%3E%3Cpath data-name= 'Rectangle 152' fill= '%23a60e3f' d= 'M131 8V0h8v8z' /%3E%3Cpath data-name= 'Rectangle 153' fill= '%23a60e3f' d= 'M180 25v-8h8v8z' /%3E%3Cpath data-name= 'Rectangle 154' fill= '%233f438b' d= 'M0 47v-8h8v8z' /%3E%3Cpath data-name= 'Rectangle 150' fill= '%23a60e3f' d= 'M1161 12V4h8v8z' /%3E%3Cpath data-name= 'Rectangle 151' fill= '%233f438b' d= 'M389 28v-8h8v8z' /%3E%3Cpath data-name= 'Rectangle 157' fill= '%233f438b' d= 'M576 24v-8h8v8z' /%3E%3Cpath data-name= 'Rectangle 152' fill= '%23a60e3f' d= 'M1259 8V0h8v8z' /%3E%3Cpath data-name= 'Rectangle 154' fill= '%233f438b' d= 'M1118 37v-8h8v8z' /%3E%3Cpath data-name= 'Rectangle 159' fill= '%233f438b' d= 'M911 28v-8h8v8z' /%3E%3Cpath data-name= 'Rectangle 150' fill= '%23a60e3f' d= 'M568 28v8h-8v-8z' /%3E%3Cpath data-name= 'Rectangle 160' fill= '%23a60e3f' d= 'M797 32v8h-8v-8z' /%3E%3Cpath data-name= 'Rectangle 158' fill= '%23a60e3f' d= 'M953 31v8h-8v-8z' /%3E%3Cpath data-name= 'Rectangle 151' fill= '%233f438b' d= 'M264 20v8h-8v-8z' /%3E%3Cpath data-name= 'Rectangle 156' fill= '%233f438b' d= 'M226 21v8h-8v-8z' /%3E%3Cpath data-name= 'Rectangle 152' fill= '%23a60e3f' d= 'M480 11v8h-8v-8z' /%3E%3Cpath data-name= 'Rectangle 155' fill= '%23a60e3f' d= 'M741 28v8h-8v-8z' /%3E%3Cpath data-name= 'Rectangle 153' fill= '%23a60e3f' d= 'M376 32v8h-8v-8z' /%3E%3Cpath data-name= 'Rectangle 154' fill= '%233f438b' d= 'M673 24v8h-8v-8z' /%3E%3C/svg%3E");
    background-position: 50%;
    background-repeat: no-repeat;
  }

  a {
    text-decoration: none;
    color: var(--bs-link-color);
  }
}
.footer__partners img:not(:last-child) {
  margin-right: 24px;
}
.footer__partners img {
  height: 2rem;
  width: auto;
}
.footer__logo img {
  width: 144px;
  height: auto;
}
@media (min-width: 768px)  {
  .footer__partners {
    margin-bottom: 36px;
  }
}