@import "~bootstrap/scss/bootstrap";
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #c10000;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #3f438b;
  --bs-secondary: #a60e3f;
  --bs-white: #fff;
  --bs-dark: #2b2b2b;
  --bs-black: #000;
  --bs-gray: #707070;
  --bs-light-gray: #b7b7b7;
  --bs-primary-rgb: 63,67,139;
  --bs-secondary-rgb: 166,14,63;
  --bs-dark-rgb: 43,43,43;
  --bs-gray-rgb: 112,112,112;
  --bs-light-gray-rgb: 183,183,183;
  --bs-white-rgb: 255,255,255;
  --bs-black-rgb: 0,0,0;
  --bs-body-color-rgb: 43,43,43;
  --bs-body-bg-rgb: 255,255,255;
  --bs-font-sans-serif: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  --bs-gradient: linear-gradient(180deg,hsla(0,0%,100%,0.15),hsla(0,0%,100%,0));
  --bs-body-font-family: cy-text,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  --bs-body-font-size: 0.75rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #2b2b2b;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0,0,0,0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #2b2b2b;
  --bs-link-hover-color: #a60e3f;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
  /* custom */
  --generalTitleColor: hsla(234.19, 46.27%, 39.41%, 1);
  --errorTitleColor: #bd0000;
  --generalActionColor: hsla(344.34, 97.41%, 45.49%, 1);
  --generalActionColorHover: #bd0000;
  --generalActionTextColor: hsla(0, 0%, 100%, 1);
  --hrColor: hsla(344.34, 97.41%, 45.49%, 1);
}
.btn {
  /* btn variables */
  --bs-btn-padding-x: 37px;
  --bs-btn-padding-y: 1rem;
  --bs-btn-font-size: 0.75rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.33;

  &.btn-sm {
    --bs-btn-padding-y: 0.25rem;
    --bs-btn-padding-x: 0.5rem;
    --bs-btn-font-size: 0.875rem;
    --bs-btn-border-radius: 0.25rem;
  }
}
@font-face {
  font-family:"cy-text";
  src:url("https://use.typekit.net/af/a15cd2/000000000000000077372554/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/a15cd2/000000000000000077372554/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/a15cd2/000000000000000077372554/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
  font-family:"cy-text";
  src:url("https://use.typekit.net/af/bfeba5/000000000000000077372555/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/bfeba5/000000000000000077372555/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/bfeba5/000000000000000077372555/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

body {
  font-size: 0.75rem;
  line-height: 1.5;
}

h1, h2, h3, h4 {
  line-height: 1.08;
  letter-spacing: -.25px;
  font-weight: 700;
  color: var(--generalTitleColor)
}
h1, h2, h3 {
  font-size: calc(1.28125rem + .375vw);
}
a {
  color: var(--generalTitleColor);
  font-weight: 700;
}
.content-wrapper {
  margin: auto;
  border-radius: 15px;
  box-shadow: 0 3px 40px 0 rgb(0 0 0 / 16%);
  background-color: #fff;
  padding: 57px 88px;
}

.form-wrapper {
  width: 100%;

  .form-heading {
    margin: 2em 0 2em 0;
    border-bottom: 1px solid #999;
  }

  .form-field {
    margin: 0 0 12px;

    label {
      font-weight: 700;
      color: var(--generalTitleColor);
    }

    label.checkbox-label {
      cursor: pointer;
      display: block;
      padding-left: 1.5em;
      text-indent: -1.5em;
      font-weight: normal;
    }

    .hint {
      margin-left: 0.5em;
      font-size: 0.9em;
    }


    &.has-error {
      input {
        border: 1px solid var(--errorTitleColor);
      }
    }
  }

  .field-error {
    border-radius: 2px;
    background: #cc3b3b;
    color: #fff;
    display: inline-block;
    font-size: 13px;
    line-height: 23px;
    margin: 12px 0;
    padding: 5px 15px 3px 15px;
  }

  .field-success {
    border-radius: 2px;
    background: #2FA32F;
    color: #fff;
    display: inline-block;
    font-size: 13px;
    line-height: 23px;
    margin: 12px 0;
    padding: 5px 15px 3px 15px;
  }
}

.btn-submit {
  background: var(--generalActionColor);
  color: var(--generalActionTextColor);
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  &:hover {
    background: var(--generalActionColorHover);
    color: var(--generalActionTextColor);
  }
}

.title-content {
  max-width: 75%;
  margin: auto;
  text-align: center;

  h3 {
    color: var(--generalTitleColor);
    font-size: calc((1.8 - 1) * 1.2vw + 1rem)
  }
  h4 {
    color: var(--generalTitleColor);
    font-size: calc((1.8 - 1) * 1.2vw + 0.6rem)
  }
}

.legend {
  color: #999999;
  font-size: 0.8em;
}

.dialog-consents {
  box-shadow: none;
  .field-description {
    font-size: .8em;
  }
}
.form-check {
  &>p {
    display: flex;
    margin-bottom: 0;
  }
}
.form-check-info {
  margin-top: 10px;
  padding-left: 38px;
}
.form-check-input {
  width: 20px;
  height: 20px;
  margin-top: -2px;
  margin-right: 18px;
  cursor: pointer;

  &:focus {
    border-color: #9fa1c5;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(63 67 139 / 25%);
  }
}
.form-check-input:checked[type=checkbox] {
  background-size: 50%;
  background-color: #fff;
  border-color: #b7b7b7;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10'%3E%3Crect data-name='Rectangle 232' width='10' height='10' rx='2' fill='%23a60e3f'/%3E%3C/svg%3E");
}

.alert-not-found {
  background-color: #a60e3f;
  color: #fff;
}

@media only screen and (max-width: 1000px) {
  .content-wrapper {
    max-width: 95%;
    padding: 24px 34px;
  }

  .title-content {
    max-width: 100%;
  }
}