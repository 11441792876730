.ua {
  footer.footer {
    position: relative;
    overflow: hidden;
    margin-top: 70px;
    padding-bottom: 0;

    &:before {
      content: none;
    }

    a {
      text-decoration: none;
      color: var(--bs-link-color);
    }
  }
  .footer__partners img:not(:last-child) {
    margin-right: 24px;
  }
  .footer__partners img {
    height: 2rem;
    width: auto;
  }
  .footer__logo img {
    width: 144px;
    height: auto;
  }
  @media (min-width: 768px)  {
    .footer__partners {
      margin-bottom: 36px;
    }
  }
}